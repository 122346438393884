<footer class="application-footer display-none-in-mobile">
    <div class="bottom-footer">
        <div class="footer-right">Ver 1.8</div>
    </div>
</footer>
<div class="application-footer display-only-in-mobile">
    <ul class="sidebar-menu-ul-list grid-row display-flex flex-row flex-justify flex-align-center">
        <li class="menu-list display-flex flex-column flex-justify flex-align-center"
            *ngFor="let navItem of navBarItems" [routerLink]="navItem.routerLink"
            [ngClass]="{ 'active-menu-item': isActiveNavitem(navItem.routerLink) }">
            <div class="svg-icon" style="width: 32px; height: 32px; margin-bottom: 10px;"
                [innerHTML]="sanitizeSvg(navItem.icon, navItem.routerLink)">
            </div>
            <p class="m-0 f-10" [ngClass]="{ 'active-nav-item-color': isActiveNavitem(navItem.routerLink) }"
                (click)="isHamburguer = !isHamburguer">
                {{ navItem.label }}
            </p>
        </li>

    </ul>
</div>
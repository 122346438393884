<div class="site-login-wrapper">
  <img src="{{ logo }}" alt="logo" class="docsnow-logo m-x0y20" />

  <div id="login-form-section">
    <form [formGroup]="form" (ngSubmit)="createPassword()">
      <div class="form-group m-x0y10">
        <label class="form-label" for="newpassword">New Password</label>
        <input id="newpassword" formControlName="newPassword" autocorrect="off" minlength="8" maxlength="20"
          [type]="viewNewPassword ? 'text' : 'password'" />
        <i alt="show" class="fa fa-eye" (click)="viewNewPassword = !viewNewPassword" *ngIf="!viewNewPassword"></i>
        <i alt="hide" class="fa fa-eye-slash" (click)="viewNewPassword = !viewNewPassword" *ngIf="viewNewPassword"></i>
      </div>

      <div class="m-x0y20 w-100">
        <div class="form-group">
          <label class="form-label" for="password">Confirm Password</label>
          <input id="password" formControlName="confirmPassword" autocorrect="off" minlength="8" maxlength="20"
            [type]="viewConfirmPassword ? 'text' : 'password'" />
          <i alt="show" class="fa fa-eye" (click)="viewConfirmPassword = !viewConfirmPassword"
            *ngIf="!viewConfirmPassword"></i>
          <i alt="hide" class="fa fa-eye-slash" (click)="viewConfirmPassword = !viewConfirmPassword"
            *ngIf="viewConfirmPassword"></i>
        </div>
        <p class="error-msg" *ngIf="
              form.get('confirmPassword').value !=
                form.get('newPassword').value &&
              form.get('confirmPassword').touched
            ">
          Both New Password & Re-Enter Password Should Match!.
        </p>
      </div>

      <input [disabled]="form.invalid" type="submit" value="Change Password"
        class="primary-button width-full margin-top-2 margin-bottom-16" [ngClass]="{'disabled-input': form.invalid}" />

      <div class="margin-bottom-16">
        Already have login and password?
        <a href="javascript:void(0);" [routerLink]="['/login']">Sign in</a>
      </div>
    </form>

    <div class="usa-alert usa-alert--error" *ngIf="
          !form.get('newPassword').valid && form.get('newPassword').touched
        ">
      <div class="usa-alert__body">
        <ul>
          <li *ngFor="let rule of passwordRules">{{ rule }}</li>
        </ul>
      </div>
    </div>
  </div>
</div>
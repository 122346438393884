import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CustomMinDirective } from "src/app/core/validators/custom-min-validator.directive";
import { CustomMaxDirective } from "src/app/core/validators/custom-max-validator.directive";
import { SuccessPageComponent } from "./shared/success-page/success-page.component";
import { NgxPaginationModule } from "ngx-pagination";
import { FeeddbackComponent } from "./features/feeddback/feeddback.component";

@NgModule({
  imports: [],
  exports: [FormsModule, ReactiveFormsModule, NgxPaginationModule],
  declarations: [
    CustomMinDirective,
    CustomMaxDirective,
    SuccessPageComponent,
    FeeddbackComponent,
  ],
})
export class AppCommonModule {}

import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { CONFIG_CONSTANTS } from "src/app/config/config";
import { PermissionsService } from "src/app/core/services/permissions.service";
import { UserInfoService } from "src/app/core/services/user-info.service";

@Component({
  selector: "app-admin-footer",
  templateUrl: "./admin-footer.component.html",
  styleUrls: ["./admin-footer.component.css"],
})
export class AdminFooterComponent implements OnInit {
  copyRightYear: string = "";
  poweredBy: string = "";
  navBarItems: any = [];
  appName: string = CONFIG_CONSTANTS.project_name;
  sanitizedSvg;
  currentRoute: string;
  constructor(
    public permissionsService: PermissionsService,
    public userInfoService: UserInfoService,
    private sanitizer: DomSanitizer,
    private router: Router
  ) {
    this.currentRoute = this.router.url;
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.currentRoute = event.urlAfterRedirects;
      });
  }

  ngOnInit(): void {
    this.copyRightYear = CONFIG_CONSTANTS.BG_COPYRIGHT_YEAR;
    this.poweredBy = CONFIG_CONSTANTS.BG_POWERED_BY;

    if (this.navBarItems.length < 1) {
      this.getPermissions();
    }
  }
  getPermissions() {
    var obj = {
      tenantCode: this.userInfoService.getTokenData("tid"),
      appName: this.appName,
    };
    this.permissionsService
      .projectPermissionByProjName(obj)
      .subscribe((response) => {
        if (response) {
          if (response && response.data && response.data.length > 0) {
            this.navBarItems = response.data;
            this.navBarItems.sort((n1, n2) => {
              if (n1.sortOrder > n2.sortOrder) {
                return 1;
              }
              if (n1.sortOrder < n2.sortOrder) {
                return -1;
              }
              return 0;
            });
          }
        }
      });
  }

  sanitizeSvg(svgString: string, navItemRouterLink: string) {
    const routerLink = this.currentRoute;
    const color = routerLink.includes(navItemRouterLink)
      ? "#263873"
      : "#1b1b1b";
    const cleanedSvg = svgString.replace(/\n/g, "").trim();
    const updatedSvg = cleanedSvg
      .replace(/fill="[^"]*"/g, `fill="${color}"`)
      .replace(/<svg /, `<svg fill="${color}" `);
    return this.sanitizer.bypassSecurityTrustHtml(updatedSvg);
  }

  isActiveNavitem(navItemRouterLink: string) {
    return this.currentRoute.includes(navItemRouterLink);
  }
}

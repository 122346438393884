<header class="top-menu">
  <div class="grid-container">
    <div class="top-header">
      <div class="top-header-brand">
        <img alt="logo" src="{{ logo }}" (click)="onLogoClick()" id="logo" />
        <img alt="logo" src="{{ mobileLogo }}" (click)="onLogoClick()" id="mobilelogo" />
        <ul class="menu-ul-list">
          <li class="menu-list" *ngFor="let navItem of navBarItems;trackBy: trackByFn">
            <a class="menu-item" [routerLinkActive]="'usa-current'" [routerLink]="navItem.routerLink">{{
              navItem.label}}
            </a>
          </li>
        </ul>
      </div>
      <div class="display-in-desktop" style="margin-left: auto;">
        <div class="grid-row usage-container m-r-20" *ngIf="this.subscriptionDetails">
          <div class="p-10">
            <div class="grid-row">
              <div [ngStyle]="{'background': gradient}" class="dataUsage-container">
              </div>
            </div>
            <span class="f-10">{{this.subscriptionDetails?.storageUsedByUser}} of
              {{this.subscriptionDetails?.userStorageSize}} used</span>
          </div>
          <div class="verticle-line"></div>
          <div class="grid-row display-flex flex-row flex-align-center p-10">
            <div class="m-r-8">
              <span class="blue-text">{{requestCount}}</span>
              <span class="f-14">/{{this.subscriptionDetails?.no_of_request_provided?
                this.subscriptionDetails?.no_of_request_provided:'∞' }}
              </span>
            </div>
            <div>
              <p class="f-10 m-0">
                Requests
              </p>
              <p class="f-10 m-0">
                Pending
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="dropdown">
        <div class="dropdown1">
          <div style="
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 6px;
            cursor: pointer;
          ">
            <div class="dropbtn">
              <div class="user-info">
                <div class="p-avtar">
                  <span class="p-avatar-text ng-star-inserted">{{
                    userLogo | uppercase
                    }}</span>
                </div>
              </div>
            </div>
            <i class="fa fa-angle-down" aria-hidden="true"></i>
          </div>
          <div class="dropdown-content">
            <ul>
              <li style="font-weight: 600; font-size: 18px">
                <div>{{ fullName }},</div>
                <div style="font-weight: 400; font-size: 14px">{{ roleName }} </div>
              </li>
              <li style="font-weight: 400; font-size: 14px">
                Timezone: {{ timezone }}
              </li>
              <li style="font-weight: 400; font-size: 14px">
                Organization: {{ tenantName }}
              </li>
              <a (click)="goCMS()" *ngIf="isAdmin"
                style="font-size: 16px; font-weight: 500; cursor: pointer;color:#263873">
                View Administrator
              </a>
              <a [routerLink]="['/docservice/profile']" style="font-size: 16px; font-weight: 500;color:#263873;">
                View Account
              </a>
              <button class="primary-button" (click)="logout()" style="width: 116px; font-size: 16px">
                Log Out
              </button>
            </ul>
          </div>
        </div>
      </div>

      <!-- Mobile SideBar -->
      <div (click)="onClick()" class="btn hemburger-icon">
        <div class="user-info">
          <div class="p-avtar">
            <span class="p-avatar-text">{{
              userLogo | uppercase
              }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
<div class="expired-content" *ngIf="(daysLeft<=10) && !(currentRoute.includes('profile')) && viewSubscriptionBanner()">
  <div class="expired-card">
    <div class="expired-text-content"><img src="../../../../assets/images/info-circle.svg">&nbsp;<span
        class="expired-info-text">
        <span *ngIf="daysLeft >0">Your subscription plan will expire in {{daysLeft}} days </span>
        <span *ngIf="daysLeft <=0">Your subscription plan has expired </span>
        <span *ngIf="isSubscriptionNeeded" class="expired-link-text" (click)="navigateToSuubscriptionPlans()"
          target="_blank">Subscribe</span>
      </span>
    </div>
  </div>
  <img src="../../../../assets/images/fi-rr-cross-small.svg" class="ps-absolute cursor-pointer"
    style="right: 20px; top: 15px;" (click)="closeBanner()">
</div>
<!-- Sidebar -->
<div class="sidebar-main display-flex flex-column flex-justify ps-relative"
  [ngClass]="isHamburguer === true ? 'sidebar-main-open' : 'hidden'">
  <img class="ps-absolute sidebar-close-icon cursor-pointer" src="../../../../assets/images/fi-rr-cross-small.svg"
    (click)="isHamburguer = !isHamburguer">
  <div>
    <p class="fw-600 f-18 m-0">
      {{ fullName }}
    </p>
    <p class="fw-400 f-12 m-0">{{ roleName }} </p>
    <div class="fw-400 f-14">
      <p>
        Timezone: {{ timezone }}
      </p>
      <p>
        Organization: {{ tenantName }}
      </p>
    </div>

    <div class="m-x0y20 flex-acc">
      <a class="f-16 fw-600" (click)="goCMS()" *ngIf="isAdmin">
        View Administrator
      </a>
      <a class="f-16 fw-600" [routerLink]="['/docservice/profile']" (click)="onClick()">
        View Account
      </a>
    </div>
    <div class="display-none-in-mobile">
      <hr class="underline" />
      <ul class="sidebar-menu-ul-list">
        <li class="menu-list" *ngFor="let navItem of navBarItems">
          <p class="p-x10y0 m-0">
            <a [routerLinkActive]="'usa-current'" [routerLink]="navItem.routerLink"
              (click)="isHamburguer = !isHamburguer">{{ navItem.label }}</a>
          </p>
        </li>
      </ul>
    </div>

  </div>

  <div>
    <div class="grid-row usage-container m-x0y20">
      <div class="p-10">
        <div class="grid-row">
          <div [ngStyle]="{'background': gradient}" class="dataUsage-container">
          </div>
        </div>
        <span class="f-10">{{this.subscriptionDetails?.storageUsedByUser}} of
          {{this.subscriptionDetails?.userStorageSize}} used</span>
      </div>
      <div class="verticle-line"></div>
      <div class="grid-row display-flex flex-row flex-align-center p-10">
        <div class="m-r-8">
          <span class="blue-text">{{requestCount}}</span>
          <span class="f-12">
            /{{ subscriptionDetails?.no_of_request_provided !== null && subscriptionDetails?.no_of_request_provided !==
            undefined
            ? subscriptionDetails.no_of_request_provided
            : '∞' }}
          </span>
        </div>
        <div>
          <p class="f-10 m-0">
            Requests
          </p>
          <p class="f-10 m-0">
            Pending
          </p>
        </div>
      </div>

    </div>
    <button class="primary-button w-100" (click)="logout()">
      Log Out
    </button>
  </div>
</div>
<div *ngIf="isHamburguer === true" class="p-component-overlay" (click)="isHamburguer = !isHamburguer"></div>
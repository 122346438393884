import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  APP_SETTINGS,
  CONFIG_CONSTANTS,
  RECAPTCHA_SITEKEY,
} from "src/app/config/config";
import { ToastCostomMessage } from "src/app/core/enum/toast-message.enum";
import { ToastTitle } from "src/app/core/enum/toast-title.enum";
import { AuthService } from "src/app/core/services/auth.service";
import { SpinnerService } from "src/app/core/services/spinner.service";
import { ToastMessageService } from "src/app/core/services/toast-message.service";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.css"],
})
export class ForgotPasswordComponent implements OnInit {
  logo: any;
  copyRightYear: string = "";
  poweredBy: string = "";
  emailID: string = "";
  siteKey: any;
  public captchaResolved: boolean = false;

  constructor(
    private toastr: ToastMessageService,
    private spinner: SpinnerService,
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.logo = APP_SETTINGS.TRANSPARENT_LOGO;
    this.siteKey = RECAPTCHA_SITEKEY.siteKey;
    this.copyRightYear = CONFIG_CONSTANTS.BG_COPYRIGHT_YEAR;
    this.poweredBy = CONFIG_CONSTANTS.BG_POWERED_BY;
  }

  forgotPassword() {
    if (this.ValidateEmail(this.emailID)) {
      var creds = {
        emailId: this.emailID,
      };
      this.spinner.show();
      this.authService.forgotPassWord(creds).subscribe(
        (result) => {
          this.spinner.hide();
          if (result?.statuscode != 1) {
            this.toastr.error(result?.message);
          } else {
            this.toastr.success(ToastCostomMessage.passwordReset);
            this.router.navigateByUrl("/login");
          }
        },
        (_) => {
          this.spinner.hide();
        }
      );
    }
  }

  resolved(captchaResponse: string) {
    this.captchaResolved =
      captchaResponse && captchaResponse.length > 0 ? true : false;
  }

  private ValidateEmail(mail) {
    if (mail) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
        return true;
      }
      this.toastr.error(ToastCostomMessage.InvalidEmail, ToastTitle.Error);
      return false;
    } else {
      this.toastr.error(ToastCostomMessage.emailRequired);
      return false;
    }
  }
}

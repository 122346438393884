<div class="site-login-wrapper">
  <img src="{{ logo }}" alt="logo" class="docsnow-logo m-x0y20" />
  <div id="login-form-section">
    <form>
      <div class="form-group margin-bottom-16">
        <label class="form-label" for="email">Email Address</label>
        <input id="email" name="email" placeholder="Email Address" type="email" [(ngModel)]="emailID" autocorrect="off"
          maxlength="256" />
        <p class="required-docs-email">
          Requires your registered DocsNow email address
        </p>
      </div>
      <div class="margin-bottom-16">
        <re-captcha name="recaptchaReactive" (resolved)="resolved($event)" [siteKey]="siteKey"></re-captcha>
      </div>
      <input type="submit" [disabled]="!captchaResolved || !emailID" value="Reset Password"
        class="primary-button margin-bottom-16" (click)="forgotPassword()" style="width: 100%"
        [ngClass]="{'disabled-button' : !captchaResolved || !emailID}" />
    </form>

    <div class="border-primary-light">
      <div class="margin-bottom-1">
        <div>
          Already have login and password?
          <a href="javascript:void(0);" [routerLink]="['/login']">Sign in</a>
        </div>
      </div>
    </div>
  </div>

</div>
<img src="../../../assets/images/bottom-lock.png" class="bottom-lock">
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ExpiredComponent } from "./expired/expired.component";
import { NotfoundComponent } from "./notfound/notfound.component";
import { UnauthorizedComponent } from "./unauthorized/unauthorized.component";
import { SessionExpiryComponent } from "./session-expiry/session-expiry.component";

const routes: Routes = [
  {
    path: "notfound",
    pathMatch: "full",
    component: NotfoundComponent,
  },
  {
    path: "unauthorized",
    pathMatch: "full",
    component: UnauthorizedComponent,
  },
  {
    path: "expired",
    pathMatch: "full",
    component: ExpiredComponent,
  },
  {
    path: "session-expired",
    component: SessionExpiryComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ErrorsRoutingModule {}
